<template>
    <Button
        v-if="$hasPermission('generate')"
        buttonWord="開始計算佣金"
        buttonStyle="hollow"
        :iconClass="
            isPksSyncAvailable && isGenerateAvailable
                ? ''
                : 'process-loading-icon'
        "
        :disabled="
            isInitSyncPks ||
            !isCommissionOpen ||
            !isPksSyncAvailable ||
            !isGenerateAvailable
        "
        @click="turnOnModal()"
    />
    <Modal title="開始計算佣金" v-model="isShowModal">
        <template v-slot:modal-content>
            <div class="alert-word margin-bottom-20">
                <p
                    v-for="(des, index) in descriptions"
                    :key="index"
                    v-html="des"
                />
            </div>

            <div
                v-for="(task, taskIndex) in taskList"
                :key="taskIndex"
                class="checkbox-wrapper"
            >
                <Checkbox
                    :label="task.label"
                    :value="task.value"
                    :disabled="task.disabled"
                    v-model="taskCheckedListValue"
                />
                <div class="error alert-word" v-if="task.errorMsg">
                    <div v-html="task.errorMsg"></div>
                </div>
                <div v-if="task.children">
                    <div
                        v-for="(subtask, subtaskIndex) in task.children"
                        :key="subtaskIndex"
                        class="checkbox-wrapper"
                    >
                        <Checkbox
                            :label="subtask.label"
                            :value="subtask.value"
                            :disabled="subtask.disabled"
                            v-model="subtaskCheckedList"
                        />
                        <div
                            class="error alert-word"
                            v-if="subtask.errorMessage"
                            v-text="subtask.errorMessage"
                        />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:modal-bottom>
            <Button
                buttonWord="取消"
                buttonStyle="grey"
                @click="turnOffModal"
            />
            <Button buttonWord="確定" buttonStyle="blue" @click="runTask()" />
        </template>
    </Modal>
    <SuccessModal v-model="isShowSuccessModal" message="已完成" />
</template>

<script>
import Modal from '@/components/Modal.vue'
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import SuccessModal from '@/containers/SuccessModal.vue'
import { adminCalcDescriptions } from '@/assets/javascripts/commission/setting'
import { generateCommissionAPI } from '@/assets/javascripts/api'

export default {
    name: 'AdminStartCalc',
    emits: ['update:success', 'update-sync-status'],
    components: {
        Modal,
        Button,
        Checkbox,
        SuccessModal
    },
    props: {
        isVisible: {
            type: Boolean
        },
        mType: {
            type: String
        },
        pksSyncStatus: {
            type: Array
        },
        fileGenerateStatus: {
            type: Object
        },
        isPksSyncAvailable: {
            type: Boolean,
            default: true
        },
        isGenerateAvailable: {
            type: Boolean,
            default: true
        },
        isCommissionOpen: {
            type: Boolean
        },
        isInitSyncPks: {
            type: Boolean
        }
    },
    methods: {
        turnOnModal: function () {
            if (!this.$hasPermission('generate')) {
                this.$showErrorMessage(
                    '此帳號尚無計算佣金的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.$setGaEvent('showAdminStartCalc', 'click-Button')
            this.isShowModal = true
        },
        turnOffModal: function () {
            this.isShowModal = false
        },
        runTask: async function () {
            this.$setGaEvent('adminStartCalc', 'click-Button')
            // 看PKS是不是在同步
            const isShowCoverLoading = true
            this.$emit('update-sync-status', isShowCoverLoading)
            if (this.isPksSyncAvailable && this.isGenerateAvailable) {
                await this.generateCommission()
            } else {
                this.$showErrorMessage('PKS正在更新中，請稍後再試')
            }
            this.turnOffModal()
        },
        generateCommission: async function () {
            if (!this.$hasPermission('generate')) {
                this.$showErrorMessage(
                    '此帳號尚無計算佣金的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            try {
                // 要執行的佣金驗算、行政總表、業務員明細(有勾才做)
                const calcTaskKeys = Object.keys(this.taskCheckedList)
                const calcTasks = calcTaskKeys.filter((taskKey) => {
                    // pksAll只是為了全選PKS同步表，不用傳給後段故移除
                    return this.taskCheckedList[taskKey] && taskKey !== 'pksAll'
                })

                // 要拉的PKS同步表(有勾才做)
                const pksTaskKeys = Object.keys(this.subtaskCheckedList)
                const pksTasks = pksTaskKeys.filter((taskKey) => {
                    return this.subtaskCheckedList[taskKey]
                })

                const payload = {
                    names: pksTasks,
                    tasks: calcTasks,
                    mType: this.mType
                }
                const res = await generateCommissionAPI(payload)
                if (res.status !== 202) {
                    this.$showErrorMessage(res.msg)
                }
            } catch (error) {
                const displayMsg =
                    error.response?.data?.displayMsg || '同步時出現錯誤'
                this.$showErrorMessage(displayMsg, '若有疑問請洽資訊客服')
                this.isPksSyncing = false
                this.isPksGenerating = false
            }
        },
        getCheckboxOption: function (statusName) {
            const status = this.fileGenerateStatus[statusName]
            if (!status) return {}
            const taskStartDate = this.$toLocalString(status.lastTaskStartAt)
            const error = status.errorString?.replace(/\n/g, '<br />') ?? ''
            const errorMessage = `錯誤發生時間: ${taskStartDate}${error}`
            const option = {
                pressSync: {
                    label: '步驟 1：PKS更新(需和步驟2同步計算)',
                    value: 'pksAll',
                    disabled: false,
                    defaultChecked: false,
                    children: this.pksSyncStatus.map((item) => {
                        let date = this.$toLocalString(`${item.startAt}`)
                        return {
                            label: `${item.description} (最後更新：${date}) `,
                            value: item.name,
                            errorMessage: item.errorMsg,
                            detail: item.detail
                        }
                    })
                },
                mainGenerateProcess: {
                    value: 'usp', //配合後端邏輯value由 fileGenerate 改為 usp
                    disabled: false,
                    defaultChecked: false,
                    label: `步驟 2：產生佣金資料(全制度) (最新版本日期：${taskStartDate})`,
                    errorMsg: status.errorString ? errorMessage : ''
                },
                adminFile: {
                    value: 'admin',
                    disabled: true,
                    defaultChecked: true,
                    label: `步驟 3：試跑行政明細 (最新版本日期：${taskStartDate})`,
                    errorMsg: status.errorString ? errorMessage : ''
                },
                salesFile: {
                    value: 'sales',
                    disabled: false,
                    defaultChecked: false,
                    label: `步驟 4：試跑業務員明細 (最新版本日期：${taskStartDate})`,
                    errorMsg: status.errorString ? errorMessage : ''
                }
            }
            return option[statusName]
        },
        // setPksUpdateTime: function () {
        //     // hover的pks更新時間
        //     const lastUpdateTime = this.pksSyncStatus
        //         .map((item) => new Date(item.startAt).getTime())
        //         .reduce((b, c) => Math.max(b, c))
        //     this.lastUpdateTime = this.$toLocalString(lastUpdateTime)
        // },
        setTaskCheckboxOptions: function () {
            Object.keys(this.tasks).forEach((taskKey) => {
                this.taskOptions[taskKey] = this.getCheckboxOption(taskKey)
            })
        },
        setDefaultChecked: function () {
            if (this.isShowModal) return //避免跳窗開啟時選項更新造成勾選變回預設
            Object.values(this.taskOptions).forEach((task) => {
                if (task.children) {
                    Object.values(task.children).forEach((subtask) => {
                        this.subtaskCheckedList[subtask.value] =
                            task.defaultChecked
                    })
                } else {
                    this.taskCheckedList[task.value] = task.defaultChecked
                }
            })
        },
        showSyncResultAlert: function () {
            const errorMessage = Object.keys(this.fileGenerateStatus).reduce(
                (memo, taskKey) => {
                    const task = this.fileGenerateStatus[taskKey]
                    const error = task.errorString?.replace(/\n/g, '<br />')
                    if (error) {
                        const taskName = this.tasks[taskKey]
                        memo = ` <br><span style="color:red">${taskName}錯誤：</span>${error}`
                    }
                    return memo
                },
                ''
            )
            if (errorMessage.length > 0) {
                this.$showErrorMessage('計算佣金時發生錯誤', errorMessage)
            } else {
                this.isShowSuccessModal = true
            }
        }
    },
    computed: {
        taskCheckedListValue: {
            get() {
                // 目前情況只設定pksAll有subtask
                const checkedList = Object.values(this.subtaskCheckedList)
                const isCheckedAll = checkedList.every((checked) => checked)
                const taskCheckedList = {
                    ...this.taskCheckedList,
                    pksAll: isCheckedAll
                }

                return taskCheckedList
            },
            set(val) {
                const isPksAllChanged =
                    val.pksAll !== this.taskCheckedList.pksAll
                this.taskCheckedList = val

                if (isPksAllChanged) {
                    Object.keys(this.subtaskCheckedList).forEach((taskKey) => {
                        const isCheckedAll = val.pksAll
                        this.subtaskCheckedList[taskKey] = isCheckedAll
                    })
                }
            }
        },
        taskList: function () {
            return Object.values(this.taskOptions)
        }
    },
    watch: {
        isShowModal: function () {
            this.setDefaultChecked()
        },
        pksSyncStatus: {
            handler() {
                this.setTaskCheckboxOptions()
                this.setDefaultChecked()
            },
            immediate: true
        },
        fileGenerateStatus: {
            handler() {
                this.setTaskCheckboxOptions()
                this.setDefaultChecked()
            },
            immediate: true
        },
        isPksSyncAvailable: function () {
            if (this.isPksSyncAvailable && this.isGenerateAvailable) {
                this.showSyncResultAlert()
            }
        },
        isGenerateAvailable: function () {
            if (this.isPksSyncAvailable && this.isGenerateAvailable) {
                this.showSyncResultAlert()
            }
        },
        subtaskCheckedList: {
            handler() {
                const subChecked = Object.values(this.subtaskCheckedList)
                //pks任一選項勾選，usp佣金也必須勾選計算
                if (subChecked?.includes(true)) this.taskCheckedList.usp = true
                if (!subChecked?.includes(false))
                    this.taskCheckedList.pksAll = true
                else this.taskCheckedList.pksAll = false
            },
            deep: true
        },
        'taskCheckedList.usp': {
            handler() {
                //usp選項沒勾選，pks所有選項不能勾選
                const usp = this.taskCheckedList.usp
                if (!usp) {
                    this.taskCheckedList.pksAll = false
                    Object.keys(this.subtaskCheckedList).forEach((taskKey) => {
                        this.subtaskCheckedList[taskKey] = false
                    })
                }
            }
        },
        'taskCheckedList.pksAll': {
            handler() {
                const pksAll = this.taskCheckedList.pksAll
                //pks全選勾選，usp佣金也必須勾選計算
                if (pksAll) {
                    this.taskCheckedList.usp = true
                    Object.keys(this.subtaskCheckedList).forEach((taskKey) => {
                        this.subtaskCheckedList[taskKey] = true
                    })
                }
            }
        }
    },
    data() {
        return {
            isShowModal: false,
            isShowSuccessModal: false,
            descriptions: adminCalcDescriptions,
            tasks: {
                pressSync: 'PKS更新',
                mainGenerateProcess: '產生佣金資料(全制度)',
                adminFile: '行政報表',
                salesFile: '業務員報表'
            },
            taskOptions: {},
            taskCheckedList: {},
            subtaskCheckedList: {}
        }
    },
    async mounted() {
        // await this.setPksUpdateTime()
    }
}
</script>

<style lang="scss" scoped>
.alert-word {
    font-size: 14px;
    color: $secondary-grey;
    p {
        margin: 0;
    }
}
.checkbox-wrapper {
    .checkbox-wrapper {
        padding-left: 20px;
    }

    :deep(.checkbox-container) {
        align-items: flex-start;
    }

    :deep(label) {
        padding: 2px 0;
    }

    .error {
        margin-left: 30px;
        color: red;
    }
}
</style>
